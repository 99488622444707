import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@views/index/home.vue'
import Login from '@views/user/login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    redirect:'/store',
  },
  {
    path: '/home',
    name: 'Home',
    component: Home,
    redirect:'/store',
    children:[
      {
         path: '/store',
         name: 'store',
         component: () => import('@/views/store/index.vue'),
       },
       {
         path: '/team',
         name: 'team',
         component: () => import('@/views/team/index.vue'),
       },
       {
         path: '/associatedStore',
         name: 'associatedStore',
         mate:{},
         component: () => import('@/views/store/associatedStore.vue')
       },
        {
        path: '/log',
        name: 'log',
        component: () => import('@/views/log/index.vue'),
      },
     ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/user/register.vue')
  },
  {
    path: '/resetpwd',
    name: 'resetpwd',
    component: () => import('@/views/user/resetpwd.vue')
  },
  {
    path: '/subAccountLogin',
    name: 'subAccountLogin',
    component: () => import('@/views/user/subAccountLogin.vue')
  },
  {
    path: '/subAccountLogin/:id',
    name: 'subAccountLoginId',
    component: () => import('@/views/user/subAccountLogin.vue')
  },
]

const router = new VueRouter({
  routes
})

export default router
