import { request } from '@/axios/request';

function getLoginInfo(){
    return JSON.parse(localStorage.getItem('userInfo'));
  }

/**
* @desc 请求后台发送验证码
*/
export const verificationCode = (data) => request('/verificationCode','POST',data)

/**
* @desc 请求后台发送注册申请
*/
export const registerAccount = (data) => request('/account','POST',data)

/**
* @desc 发送重置密码请求
*/
export const resetPwdAccount = (data) => request('/account','PATCH',data)

/**
* @desc 发送登录请求
*/
export const login = (data) => request('/session','POST',data)

/**
* @desc 重发请求
*/
export const resetSession = (data) => request('/session','PUT',data)


/**
* @desc 修改子账号密码请求
*/
export const resetPwdChildAccount = (data) => request(`/account/${getLoginInfo().account_id}/childAccount/${getLoginInfo().id}/password`,'PUT',data)
