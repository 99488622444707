<template>
  <div class="flex-column parcel-content" @click="isShowPersonal = false">
    <div class="header flex-justify-between-center">
      <div class="img-wrapper flex-center">
        <img height="32" src="@/assets/img/shoppaas.png" alt="">
      </div>
      <div class="user-info-wrapper flex-center" :class="{'user-info-choose':isShowPersonal}" @click.stop="isShowPersonal = !isShowPersonal">
        <div class="head-portrait">
          <img src="https://cdn.shoppaas.com/assets/img/logo.png" alt="">
        </div>
        <div class="user-info-text flex-column">
          <div>{{ userInfoWrapper.name }}</div>
          <div>{{ userInfoWrapper.email }}</div>
        </div>
        <div class="personal-data-wrapper" v-show="isShowPersonal" @click.stop="isShowPersonal = true">
          <div class="personal-data">
            <div class="flex-align-items-center" @click="personalDialog = true">
              <span class="iconfont icon-gerenzhongxin-gerenxinxi"></span>
              <span>个人资料</span>
            </div>
            <div class="flex-align-items-center" @click="logout">
              <span class="iconfont icon-logout"></span>
              <span>登出</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- style="overflow: auto;" -->
    <div class="flex-1 flex" style="overflow: auto;">
      <div class="menu-wrapper flex-column">
        <el-menu :default-active="defaultActive" class="el-menu-vertical-demo" :collapse="isCollapse" :router="true">

          <el-menu-item index="/store">
            <i class="el-icon-goods"></i>
            <span slot="title">店铺</span>
          </el-menu-item>
          <el-menu-item index="/team" v-if="isShowteam">
            <i class="el-icon-s-custom"></i>
            <span slot="title">团队</span>
          </el-menu-item>
          <el-menu-item index="/log" v-if="isShowlog">
            <i class="el-icon-tickets"></i>
            <span slot="title">日志</span>
          </el-menu-item>
        </el-menu>
        <!-- <div class="collapse-wrapper flex-center" @click="isCollapse = !isCollapse">
          <i :class="['icon', isCollapse ? 'el-icon-arrow-right' : 'el-icon-arrow-left']"></i>
        </div> -->
      </div>
      <div class="flex-1" style="overflow: auto;">
        <router-view></router-view>
      </div>
    </div>

    <el-dialog title="个人资料" :visible.sync="personalDialog" width="550px" :close-on-click-modal="false">
      <div>
        <el-form label-position="right" :rules="rules" label-width="100px" ref="personalForm" :model="personalData">
          <el-form-item label="合作伙伴ID:">
            <el-input v-model="personalData.account_id" readonly class="readonly-input"></el-input>
          </el-form-item>
          <el-form-item label="企业名称:">
            <el-input v-model="personalData.company" readonly class="readonly-input"></el-input>
          </el-form-item>
          <el-form-item :label="isAdmin ? '邮箱:' : '用户名'">
            <el-input v-model="personalData.email" disabled></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input v-model="personalData.password" type="password" placeholder="请输入密码"></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="personalData.confirmPassword" type="password" placeholder="请确认密码"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetPersonalData">重置</el-button>
        <el-button type="primary" @click="personalConfrim" :loading="dialogLoading">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { resetPwdAccount, resetPwdChildAccount } from '@/api/user'
export default {
  data() {
    return {
      isShowteam: true,
      isShowlog: false,
      userInfoWrapper: {
        name: '',
        email: ''
      },
      defaultActive: '',
      isCollapse: false, // 控制菜单是否折叠
      isShowPersonal: false, // 控制个人资料弹窗是否显示
      personalDialog: false,
      dialogLoading: false,
      isAdmin: true,
      personalData: {
        account_id: '',
        email: '',
        password: '',
        confirmPassword: '',
        company: ''
      },
      rules: {
        password: [
          { validator: this.validatorPassword, required: true, trigger: 'blur' }
        ],
        confirmPassword: [
          { validator: this.validatorConfirmPassword, required: true, trigger: 'blur' }
        ],
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    if (!localStorage.getItem('userInfo')) {
      next({ path: 'login' })
    } else {
      next()
    }
  },
  created() {
    this.defaultActive = this.$route.fullPath === '/' ? '/store' : this.$route.fullPath
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.isAdmin = userInfo.isAdmin
    this.userInfoWrapper.name = userInfo.company
    this.personalData.account_id = userInfo.account_id
    this.personalData.company = userInfo.company;
    if (userInfo.isAdmin) {
      this.personalData.email = userInfo.email
      this.userInfoWrapper.email = userInfo.email
    } else {
      this.userInfoWrapper.email = userInfo.name
      this.personalData.email = userInfo.name
      /**
      * 设置团队菜单栏权限
      */
      if (!userInfo.auths.some(item => item.title === '团队')) {
        this.isShowteam = false
      }
    }
  },
  methods: {
    /**
    * @desc 退出登录
    */
    logout() {
      this.$confirm('确定退出登录嘛?', '提示').then(() => {
        localStorage.removeItem('userInfo')
        location.reload()
      }).catch(() => { })
    },
    /**
    * @desc 更改个人资料
    */
    personalConfrim() {
      this.$refs.personalForm.validate((valid) => {
        if (valid) {
          this.dialogLoading = true

          if (this.isAdmin) {
            const data = {
              email: this.personalData.email,
              password: this.personalData.password,
            }
            resetPwdAccount(data).then(() => {
              this.$message.success('修改密码成功')
              this.personalDialog = false
            }).finally(() => {
              this.dialogLoading = false
            })
          } else {
            const data = {
              password: this.personalData.password,
            }
            resetPwdChildAccount(data).then(() => {
              this.$message.success('修改密码成功')
              this.personalDialog = false
            }).finally(() => {
              this.dialogLoading = false
            })
          }
        } else {
          return false
        }
      })
    },
    /**
    * @desc 重置个人资料弹窗内部数据
    */
    resetPersonalData() {
      this.personalData.password = ''
      this.personalData.confirmPassword = ''
    },
    /**
   * @desc 验证密码是否添加并符合要求
   */
    validatorPassword(rule, value, callback) {
      if (!value) return callback(new Error('请输入密码'))
      if (!(/^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,30}$/.test(value))) {
        return callback(new Error('密码必须包含小写字母、大写字母、数字和特殊符号中的三个, 且长度8-30位'))
      }
      callback()
    },
    /**
    * @desc 验证确认密码是否与密码一致且密码是否填写
    */
    validatorConfirmPassword(rule, value, callback) {
      if (!this.personalData.password) {
        value = ''
        return callback(new Error('请先输入密码'))
      }
      if (value !== this.personalData.password) {
        return callback(new Error('两次密码不一致'))
      }
      callback()
    },
  }
}
</script>

<style lang='scss' scoped>
.header {
  flex-shrink: 0;
  padding-left:24px;
  height: 56px;
  background-color: #4e73df;
  color: #fff;

  .img-wrapper {
    width: 126px;
    height: 32px;
  }

  .user-info-wrapper {
    position: relative;
    padding: 0 24px;
    height: 100%;
    min-width: 140px;
    cursor: pointer;
    &:hover{
      background-color: #4363D5;
    }
    &>*:first-child{
      margin-right: 8px;
    }
    .head-portrait {
      width: 34px;
      height: 34px;
      border-radius: 100%;
      overflow: hidden;
    }

    .user-info-text {
      &>*:first-child{
        margin-bottom: 4px;
      }
    }

    .personal-data-wrapper {
      position: absolute;
      top: 56px;
      left: 0;
      padding: 10px 0;
      width: 80%;
      min-width: 150px;
      z-index: 2;
    }

    .personal-data {
      padding: 14px 0;
      width: 100%;
      color: #222;
      background: #fff;
      border-radius: 4px;
      font-size: 14px;
      box-shadow: 0 1px 6px 3px rgb(0 0 0 / 7%);

      .flex-align-items-center {
        padding: 0 24px;
        height: 36px;
        .iconfont{
          margin-right: 8px;
        }
        &:hover {
          color: #4ea0ec;
          background-color: #f9fafc;
        }

        .iconfont {
          font-size: 18px;
        }
      }
    }
  }
  .user-info-choose{
    background-color: #4363D5;
  }
}

.menu-wrapper ::v-deep {
  .el-menu {
    width: 240px;
    height: 100%;
    background-color: #F9FAFC;
    .el-menu-item{
      color:#222;
      &:hover{
        background-color:#F0F3F5;
      }
      &>[class^=el-icon-]{
        margin-right: 8px;
        width: 18px;
      }
    }
    .is-active{
      position: relative;
      color:#4E73DF;
      background-color: #EAEEF0;
    }
    .is-active::before{
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background-color: #4e73df;
    }
  }

  .el-menu--collapse {
    width: 64px;
  }
}

.collapse-wrapper {
  padding: 10px 0;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  font-size: 30px;
  cursor: pointer;
}

::v-deep .el-menu {
  background-color: inherit;
}

::v-deep .el-menu--collapse {
  .el-tooltip {
    display: flex !important;
    align-items: center;
  }
}

::v-deep .el-menu {
  padding-top: 10px;
}

::v-deep .el-menu-item,
::v-deep .el-submenu__title {
  padding: 9px 0 9px 10px;
  height: auto;
  line-height: 1;
}
</style>
