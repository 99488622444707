import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/element_theme/index.css'
import '@/assets/ali_icon/iconfont.css'
import '@/style/resetElement.css'
import './style/base.css';
import '@/style/public.scss'
import {
  Button,Dialog,Menu,Submenu,MenuItem,MenuItemGroup,
  Input,Radio,Select,Option,OptionGroup,Table,TableColumn,
  Form,FormItem,Image,Loading,MessageBox,Checkbox,
  Statistic,Icon,Tabs,TabPane,Pagination,CheckboxGroup,Tooltip,
  Tree,
} from 'element-ui';
import CustomMessage from '@components/global/Message'

Vue.use(Button).use(Dialog).use(Menu).use(Submenu).use(MenuItem)
.use(MenuItemGroup).use(Input).use(Radio).use(Select).use(Option)
.use(OptionGroup).use(Form).use(FormItem).use(Table).use(TableColumn)
.use(Image).use(Checkbox).use(Statistic).use(Icon).use(Tabs).use(TabPane)
.use(Pagination).use(CheckboxGroup).use(Loading).use(Tooltip).use(Tree)

Vue.prototype.$loading = Loading.service;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$message = CustomMessage;
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
