import axios from 'axios'
import router from '../router/index'
import { Message } from 'element-ui'
import { resetSession } from '@/api/user'


axios.interceptors.request.use(config => {
    if(localStorage.getItem('userInfo')){
        config.headers.session = JSON.parse(localStorage.getItem('userInfo')).session
    }
    return config
})
/**
* @Desc 配置响应参数
*/
axios.interceptors.response.use(
    async response => {
        // 当状态码是200时进入resolve状态,否则进入reject
        if (response.status === 200) {
            // code 非1时则代表出错,抛出错误提示
            if(response.data.code !== 0){
                if(response.data.code === 10001){ // session过期
                    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
                    const data = {
                        session:userInfo.session
                    }
                    const request = response.config

                    const res = await resetSession(data);
                    userInfo.session = res.data;
                    localStorage.setItem('userInfo',JSON.stringify(userInfo));

                    return axios(request);
                    
                }else if(response.data.code === 10003){
                    Message({
                        showClose: true,
                        message: '登录过期,请重新登录',
                        type: 'error',
                        duration:5000,
                    });
                    //清除token
                    localStorage.removeItem('userInfo');
                    location.reload()
                }else{
                    Message({
                        showClose: true,
                        message: response.data.msg,
                        type: 'error',
                        duration:5000,
                    });
                } 
            }
            return Promise.resolve(response.data);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                //401:未登录
                case 401:
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath
                        }
                    });
                    break;
                //403禁止访问
                case 403:
                    Message({
                        showClose: true,
                        message: '禁止访问',
                        type: 'error',
                        duration:5000,
                    });
                    break;
                    //404请求不存在
                case 404:
                    Message({
                        showClose: true,
                        message: '请求不存在',
                        type: 'error',
                        duration:5000,
                    });
                    break;
                    //其他错误，直接抛出错误提示
                default:
                    Message({
                        showClose: true,
                        message: error.response.data.message,
                        type: 'error',
                        duration:5000,
                    });
            }
        }
        return Promise.reject(error.response);
    }
);

/**
* @desc 封装请求,主要解决一些没必要的代码,例如判断接口是否成功,当接口已经成功访问到了后台,
* 但是后台报错的话,catch的error对象会有isRequestSuccess参数,值为200,当后台报错需要重置数据的话可以在catch回调里面完成
* @param { String } url - 接口路径
* @param { String } method - 具体的请求类型
* @param { Object } params - 请求的参数
*/
export const request = (url,method,params)=>{
    const data = {
        url:process.env.NODE_ENV !== 'development' ? url :'/api' + url,
        // url: url,
        method,
        headers:{
            'Content-Type':'application/json;charset=UTF-8',
        }
    }

    if(method.toUpperCase() === 'GET'){
        data.params = params
    }else{
        data.data = params
    }
    return new Promise((resolve,reject) => {
        axios(data).then(res => {
            if(res.code !== 0){
                return reject({isRequestSuccess:200,...res})
            }
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

export default axios;
