<template>
  <div class="parcel-content account-wrapper">
    <div class="main-wrapper">
      <div class="main-header flex-align-items-center">
        <img src="@/assets/img/logo.png" alt="logo">
        <span style="
        padding: 0px 10px;
        font-size: 30px;
    ">·</span>
    <span style="
    font-size: 25px;
    padding-bottom: 2px;
    font-family: Segoe Print;
    font-weight: bold;
">Partners</span>
      </div>
      <div class="title">登陆Shoppaas合作伙伴账号</div>
      <div class="tips">您可以通过自己的账号与Shoppaas合作</div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm">
        <el-form-item prop="email">
          <el-input v-model="ruleForm.email" placeholder="邮箱">
            <template slot="prepend">
              <span class="iconfont icon-7-Email"></span>
            </template>
          </el-input>
        </el-form-item>

        <el-form-item prop="password">
          <el-input v-model="ruleForm.password" placeholder="密码" type="password" @keyup.enter.native="loging">
            <template slot="prepend">
              <span class="iconfont icon-mima"></span>
            </template>
          </el-input>
        </el-form-item>

        <el-button type="primary" class="form-submit-bth" @click="loging" :loading="loginLoading">登录</el-button>
      </el-form>
      <div class="operation-wrapper">
        <router-link to="/register">立即注册</router-link>
        <div class="division"></div>
        <router-link to="/resetpwd">忘记密码?</router-link>
      </div>
      <div class="go-refister-wrapper">您不是合作伙伴主账号?
        <span>切换</span><router-link to="/subAccountLogin">子账号登录</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { login } from '@/api/user'
export default {
  name:'login',
  data(){
    return {
      loginLoading:false,
      ruleForm:{},
      rules:{
        email:[
          { validator: this.validatorEmail, trigger: 'blur' }
        ],
        password:[
          { required:true,message:'请输入密码',trigger:'blur'}
        ]
      }
    }
  },
  created(){},
  methods:{
    loging(){
      this.$refs.ruleForm.validate((valid) => {
        if(valid){
          this.loginLoading = true
          const data = {
            type:'1',
            ...this.ruleForm
          }
          login(data).then(res => {
            localStorage.setItem('userInfo',JSON.stringify(res.data))
            this.$message.success('登录成功')
            setTimeout(()=>{
              this.$router.push('/home')
            },500)
          }).finally(()=>{
            this.loginLoading = false
          })
        }else{
          return false
        }
      })
    },
    /**
    * @desc 验证邮箱是否添写并符合要求
    */
    validatorEmail(rule, value, callback){
      if(!value){
        return callback(new Error('请输入邮箱'))
      }

      if(!(/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/.test(value))){
        return callback(new Error('请输入正确的邮箱'))
      }
      callback()
    },
  }
}
</script>

<style lang='scss' scoped>
.account-wrapper .main-wrapper .demo-ruleForm .form-submit-bth{
  width: 100%;
}
.account-wrapper .main-wrapper .go-refister-wrapper span{
  margin-left: 12px;
}
.account-wrapper .main-wrapper .go-refister-wrapper a{
  margin-left: 5px;
}
.operation-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  height: 30px;
  font-size: 18px;
  a{
    color: #969696;
  }
  .division{
    margin: 0 10px;
    width: 1px;
    height: 100%;
    background-color: #c1c1c1;
  }
}
.account-wrapper .main-wrapper{
  width: 545px;
}
</style>>
