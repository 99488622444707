
import { Message } from 'element-ui'


// 没有判断是否vNode，所以只支持字符串传递的才会修改默认参数，如果是vNode节点的话则需要自己配置
const defaultOptions = {
  offset:24,
  showClose:true,
  duration:5000
}
const CustomMessage = function(options){
  options = options || {}
  if(typeof options === 'string'){
    options = {
      message:options,
      ...defaultOptions
    }
  }
  return Message(options)
};

['success', 'warning', 'info', 'error'].map(type => {
  CustomMessage[type] = (options) => {
    options = options || {}
    if(typeof options === 'string'){
      options = {
        message:options,
        ...defaultOptions
      }
    }
    return Message({...options,type})
  }
})

export default CustomMessage;
